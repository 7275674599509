export default class {
    constructor() {
        this.breakPoint = 768;

        this.winW = $(window).width();
        $(window).on('load resize',()=>{this.winW = $(window).width();})

        this.winH = $(window).height();
        $(window).on('load resize',()=>{this.winH = $(window).height();})
    }
}
