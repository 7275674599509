//UTILITY
import Env from '../_app-modules/class-utility/Env';

//HELPER
import Point from '../_app-modules/helper/Point';

class App extends TinyEmitter{
    constructor() {
        super();

        //UTILITY
        this.env = new Env();
        this.point = new Point();
    }
    start(){
        this.emit('START_BEFORE');

        $(()=>{
            this._startpage();
            this.emit('START_AFTER');
        });
    }
    _startpage(){
        this.page = new this.page();
        this.page.init();
    }
}

window.app = new App();
